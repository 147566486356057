// @ts-nocheck

import { Options, Vue } from 'vue-class-component'
import { authService } from '@/http/auth'
import router from '@/router/index'

@Options({
  data() {
    return {
      data: {
        instance: this.$route.params.instance,
        email: this.$route.params.email,
        role_id: this.$route.params.role_id,
        signature: this.$route.params.signature,
        child_user_id: '',
        route: '',
      },
      form: {
        email: '',
        password: '',
        instance: '',
        role_id: '',
        child_user_id: ''
      },
      failed: false,
      failedLogin: false
    }
  },
  methods: {
    handleSubmit: async function () {
      await Promise.all([
        authService.authLogin(this.form),
      ]).then(results => {
        if (results[0] == false) {
          this.failedLogin = true
        } else {
          let user = JSON.parse(localStorage.getItem('user') || '{}')
          let active_role_id = user.active_role_id
          localStorage.setItem('instance', this.form.instance)
          if (active_role_id == 4 || active_role_id == 5) {
            router.replace({ path: '/user/bill' })
          } else {
            var route = '/'
            if (this.data.route) {
              route = this.data.route
            }
            router.replace({ path: route })
          }
        }
      }).catch((e) => {
        this.failed = true
        console.log(e)
      })
    }
  },
  async mounted () {
    if (this.data.instance != undefined && this.data.email != undefined && this.data.role_id != undefined && this.data.signature != undefined) {

      if (this.$route.query.child_user_id) {
        this.data.child_user_id = this.$route.query.child_user_id
      }

      if (this.$route.query.route) {
        this.data.route = this.$route.query.route
      }

      await Promise.all([
        authService.token(this.data),
      ]).then(results => {
        // redirect ke user / admin sesuai dengan role id
        if (results[0] == false) {
          this.failed = true
        } else {
          let user = JSON.parse(localStorage.getItem('user') || '{}')
          let active_role_id = user.active_role_id
          localStorage.setItem('instance', this.data.instance)
          if (active_role_id == 4 || active_role_id == 5) {
            router.replace({ path: '/user/bill' })
          } else {
            var route = '/'
            if (this.data.route) {
              route = this.data.route
            }
            router.replace({ path: route })
          }
        }
      }).catch((e) => {
        this.failed = true
        console.log(e)
      })
    } else {
      this.failed = true
    }
  },
})
export default class Auth extends Vue {}
