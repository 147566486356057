<template>
  <div class="login-container">
    <form @submit.prevent="handleSubmit">
      <div>
        <label for="email">Email:</label>
        <input id="email" type="email" v-model="form.email" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input id="password" type="password" v-model="form.password" required />
      </div>
      <div>
        <label for="instance">Instance:</label>
        <input id="instance" type="text" v-model="form.instance" required />
      </div>
      <div>
        <label for="role_id">Role ID:</label>
        <select v-model="form.role_id" required>
          <option value="1">Super Admin</option>
          <!-- <option value="2">Staff</option>
          <option value="3">Teacher</option> -->
          <option value="4">Student</option>
          <option value="5">Parent</option>
        </select>
      </div>
      <div v-if="form.role_id == 5">
        <label for="child_user_id">Child User ID:</label>
        <input id="child_user_id" type="number" v-model="form.child_user_id" required />
      </div>
      <button type="submit">Login</button>
    </form>
    <div v-if="failedLogin" class="error-message">
      Login failed. Please check your credentials.
    </div>
  </div>
</template>

<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: red;
  margin-top: 1em;
}

label {
  float: left;
}

input, select {
  width: 100%;
  margin-bottom: 1em;
}

/* add red * in label if next element is required */
label:has(+ input:required):after,
label:has(+ select:required):after {
  content: '*';
  color: red;
  margin-left: 4px;
}
</style>
<script language="ts" src="./scripts/auth.ts"></script>
